body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.App {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto; /* Ensure scrollbars are always visible */
}

img {
  border: 2px solid black;
  box-shadow: 0px 0px 10px 0px #000;
}

.img-no-style {
  border: none !important;
  box-shadow: none !important;
}

//.App-logo {
//}
//
//@media (prefers-reduced-motion: no-preference) {
//  .App-logo {
//    animation: App-logo-spin infinite 20s linear;
//  }
//}
//
//.App-link {
//  color: #61dafb;
//}
//
//@keyframes App-logo-spin {
//  from {
//    transform: rotate(0deg);
//  }
//  to {
//    transform: rotate(360deg);
//  }
//}

$link: #11d3d5;

.wrap {
  p {
    a {
      transform: translate(-50%,-50%);
      position: relative;
      overflow: hidden;
      text-decoration: none;
      color: $link;
      &::after {
        content: "";
        background: rgba($link, 0.25);
        position: absolute;
        left: 12px;
        bottom: -6px;
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        z-index: -1;
        transition: 0.35s cubic-bezier(0.25, 0.1, 0, 2.05);
      }
      &:hover:after {
        left: -5px;
        bottom: -5px;
        width: 110%;
        height: 150%;
      }
    }
  }
}

.link-effect {
    transform: translate(-50%,-50%);
    position: relative;
    overflow: hidden;
    text-decoration: none;
    color: $link;
    &::after {
      content: "";
      background: rgba($link, 0.25);
      position: absolute;
      left: 12px;
      bottom: -6px;
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      z-index: -1;
      transition: 0.35s cubic-bezier(0.25, 0.1, 0, 2.05);
    }
    &:hover:after {
      left: -5px;
      bottom: -5px;
      width: 110%;
      height: 150%;
    }
  }

.plain-link {
  color: white;
  text-decoration: none;
  font-family: inherit;
  font-size: inherit;
  margin-left: 10px;
  margin-right: 10px;
}

.plain-link:hover {
  cursor: pointer;
  color: white;
  text-decoration: none;
}

